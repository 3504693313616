<template>
  <div style="width:100vw">
    <!--     第一栏-->
    <div style="position: relative">
      <div style="width: 100%;height: 2.88rem;background-color: white;position: relative">
        <div
          style="position: absolute;top:0.768rem;left: 0.64rem;font-family: PingFang SC, PingFang SC;font-weight: bold;font-size: 0.96rem;color: #040407;">
          玄易无限
        </div>
        <img :src="menu" alt="" style="width: 1.2672rem;position: absolute;top:0.8rem;right: 0.64rem"
             @click="clickPop()">
      </div>
      <div style="display: flex;justify-content: center;height: 4.128rem">
        <div style="width: 100%;display: flex;justify-content: center;box-sizing: border-box">
          <div
            style="box-sizing: border-box;width: 31%;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.96rem;color: #0D0D0D;display: flex;align-items: center">
            <div style="margin-left: 0.64rem">下载中心</div>
          </div>
          <div
            style="box-sizing: border-box;width: 17.5%;display: flex;justify-content: center;align-items: center;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.704rem;color: #0D0D0D;">
            安卓版
          </div>
          <div
            style="box-sizing: border-box;width: 17.5% ;display: flex;justify-content: center;align-items: center;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.704rem;color: #0D0D0D;">
            iPhone版
          </div>
          <div
            style="box-sizing: border-box;width: 17.5% ;display: flex;justify-content: center;align-items: center;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.704rem;color: #0D0D0D;">
            小程序版
          </div>
          <div
            style="box-sizing: border-box;width: 17.5% ;display: flex;justify-content: center;align-items: center;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.704rem;color: #0D0D0D;">
            网页版
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center">
        <div
          style="width: 100%;display: flex;justify-content: center">
          <div
            style="box-sizing: border-box;width: 31%;display: flex;align-items: center ">
            <div style="margin-left: 0.64rem;width: 2.496rem"><img :src="icon_1" width="100%"></div>
            <div style="margin-left: 0.352rem">
              <div
                style="font-weight: bold;font-size: 0.704rem;color: #0D0D0D;">玄易排盘
              </div>

              <div
                style="font-weight: 400;font-size: 0.416rem;color: #0D0D0D;margin-top: 0.128rem">黄历占卜万年历算命
              </div>
            </div>
          </div>
          <div style="box-sizing: border-box;width: 17.5%;position: relative;padding: 0.48rem">
            <img :src="Android" width="100%" @click="go('xuanyi_anzuo')">
          </div>
          <div style="box-sizing: border-box;width: 17.5%;position: relative;padding: 0.48rem">
            <img :src="iPhone" width="100%" @click="go('xuanyi_ios')">
          </div>
          <div style="box-sizing: border-box;width: 17.5%;;padding: 0.48rem">
            <img :src="dev" width="100%">
          </div>
          <div style="box-sizing: border-box;width: 17.5%;padding: 0.48rem">
            <img :src="dev" width="100%">
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 1.28rem">
        <div
          style="width: 100%;display: flex;justify-content: center;">
          <div
            style="box-sizing: border-box;width: 31%;display: flex;align-items: center ">
            <div style="margin-left: 0.64rem;width: 2.496rem"><img :src="icon_3" width="100%"></div>
            <div style="margin-left: 0.352rem">
              <div
                style="font-weight: bold;font-size: 0.704rem;color: #0D0D0D;">卜卜排盘
              </div>

              <div style="font-weight: 400;font-size: 0.416rem;color: #0D0D0D;;margin-top: 0.128rem">八字排盘算命国学
              </div>
            </div>
          </div>
          <div style="box-sizing: border-box;width: 17.5%;position: relative;padding: 0.48rem">
            <img :src="Android" width="100%" @click="go('bubu_anzuo')">

          </div>
          <div style="box-sizing: border-box;width: 17.5%;position: relative;padding: 0.48rem">
            <img :src="iPhone" width="100%" @click="go('bubu_ios')">

          </div>
          <div style="box-sizing: border-box;width: 17.5%;;padding: 0.48rem">
            <img :src="dev" width="100%">
          </div>
          <div style="box-sizing: border-box;width: 17.5%;padding: 0.48rem">
            <img :src="dev" width="100%">
          </div>
        </div>
      </div>
      <div style="position: relative;margin-top: 40px">
        <bottom></bottom>
      </div>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/comment'
import bottom from "@/components/bottom";

export default {
  mixins: [mixin],
  name: 'App',
  components: {bottom},
  data() {
    return {
      // menu: require('@/assets/div_2/menu.png'),
      // icon_1: require('@/assets/div_1/icon_1.png'),
      // icon_3: require('@/assets/div_1/icon_3.png'),
      // dev: require('@/assets/div_1/dev.png'),
      // Android: require('@/assets/div_1/Android.png'),
      // iPhone: require('@/assets/div_1/iPhone.png'),
      // an: require('@/assets/div_1/an.png'),
      // bubuAn: require('@/assets/div_1/bubuAn.png'),
      // bubuIos: require('@/assets/div_1/bubuIos.png'),
      // ios: require('@/assets/div_1/ios.png'),
      // icon_4: require('@/assets/div_1/icon_4.png'),
      // icon_2: require('@/assets/div_1/icon_2.png'),
      // mobile_bg9: require('@/assets/div_2/mobile_bg9.png'),
      xuanyiAndroidShow: false,
      xuanyiIosShow: false,
      bubuAndroidShow: false,
      bubuIosShow: false,
    }
  },
  methods: {
    go(v) {
      if (v === 'xuanyi_anzuo') {
        window.location.href = 'https://www.ixybz.com/download//'
      } else if (v === 'xuanyi_ios') {
        window.location.href = 'https://apps.apple.com/us/app/%E7%8E%84%E6%98%93%E6%8E%92%E7%9B%98-%E5%85%AB%E5%AD%97%E5%91%A8%E6%98%93%E7%AE%97%E5%91%BD%E7%8E%84%E5%AD%A6%E5%9B%BD%E5%AD%A6%E6%8E%92%E7%9B%98app/id6473688731'
      } else if (v === 'bubu_anzuo') {
        window.location.href = 'https://www.bububz.com/download/'
      } else if (v === 'bubu_ios') {
        window.location.href = 'https://apps.apple.com/cn/app/%E5%8D%9C%E5%8D%9C%E6%8E%92%E7%9B%98-%E5%85%AB%E5%AD%97%E6%8E%92%E7%9B%98%E7%AE%97%E5%91%BD%E5%9B%BD%E5%AD%A6%E8%BD%AF%E4%BB%B6/id1630537960'

      }

    },
    clickPop() {
      this.$router.push('/pop')
    }
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}


</style>
