<template>
  <div style="width: 100%">
    <div
      style="
        width: 100%;
        height: 2.88rem;
        background-color: white;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          top: 0.768rem;
          left: 0.64rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 0.96rem;
          color: #040407;
        "
      >
        易喆云
      </div>
      <!-- <img v-show="isHome()" :src="menu" alt="" style="width: 1.2672rem;position: absolute;top:0.8rem;right: 0.64rem"
           @click="goHome(8)"> -->
    </div>
    <div style="width: 100%; position: relative">
      <img :src="mobile_bg1" width="100%" />
      <div
        style="
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="width: 80%">
          <img :src="black" class="div_a_a" style="width: 100%" />
        </div>
        <div
          style="
            width: 3.5rem;
            display: flex;
            flex-direction: column;
            justify-content: end;
            position: absolute;
            right: 1.9rem;
            bottom: 6rem;
          "
        >
          <img
            :src="mobile_xiazai_button"
            class="div_a_c"
            @click="goHome(11)"
          />
        </div>
      </div>
    </div>
    <div style="width: 100%; background: #f2efec">
      <div
        style="
          height: 3.584rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img :src="text_1" alt="" style="width: 7.11008rem" />
      </div>
      <div
        style="
          margin: 0rem 1.344rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.736rem;
          line-height: 1.088rem;
          color: #000000;
        "
      >
        真太阳时精准排盘，多维度解读您的命理。我们的应⽤⽀持公历、
        农历和甲⼦历排盘，涵盖年⽉⽇时四柱、胎元、命宫、身宫、⼤
        运、流年、流⽇、流时等多个⽅⾯。不仅如此，还包括⼗⼆⻓⽣、
        神煞、纳⾳、⼈元司令分野等运算，为您提供更为全⾯的命理信 息。
      </div>
      <div
        style="
          margin: 0.32rem 2.496rem 0rem 1.312rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.48rem;
          color: #aeaeae;
          line-height: 0.736rem;
          text-align: left;
        "
      >
        True sun time precise arrangement, multi-dimensional interpretation of
        your numerology. Our application supports the Gregorian calendar, lunar
        calendar and Jiazi calendar platters, H anCover the four pillars, fetal
        elements, life palace, body palace, great luck, time, flow day, flow
        time and other aspects. Not only that,It also includes twelve
        Changsheng, Shen Sha, N a Yin, human Yuan commander,and other
        operations to provide you with more comprehe nsive numerological
        information.
      </div>
      <img :src="mobile_bg2" style="width: 100%; margin-top: 2.144rem" />
      <div
        style="
          height: 3.584rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img :src="text_2" alt="" style="width: 7.11008rem" />
      </div>
      <div
        style="
          margin: 0rem 1.344rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.736rem;
          line-height: 1.088rem;
          color: #000000;
        "
      >
        通过AI⼤数据运算，AI智能提示功能为您提供⼋字旺衰、⼋字格
        局、同党异党分析、五⾏能量占⽐等多⽅位信息。瞬间洞悉⼋字
        的精华，助您在批断命理时得⼼应⼿。让⼤数据的⼒量成为您的
        智囊，助您轻松断准⼋字！
      </div>
      <div
        style="
          margin: 0.32rem 2.496rem 0rem 1.312rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.48rem;
          color: #aeaeae;
          line-height: 0.736rem;
          text-align: left;
        "
      >
        Through AI big data operation, AI intelligent prompt function provides
        you with eight-figure prosperity and decline, eight-figure
        gridMulti-directional information such as bureau, party analysis, energy
        proportion of five elements, etc. Instant insight into the figure
        eightThe ess ence to help you in the determination of numerology. Make
        the power of big data yoursThin k tank, help you easily break the
        eight-figure!
      </div>
      <img :src="mobile_bg3" style="width: 100%; margin-top: 2.144rem" />
      <div
        style="
          height: 3.584rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img :src="text_3" alt="" style="width: 7.11008rem" />
      </div>
      <div
        style="
          margin: 0rem 1.344rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.736rem;
          line-height: 1.088rem;
          color: #000000;
        "
      >
        为您梳理《穷通宝鉴》、《滴天髓》、《三命通会》、《⼱箱秘
        术语》等名著的经典论述，智能匹配每个⼋字。⽆需翻书，⼀触
        即得深邃古籍智慧，助您更全⾯地理解⼋字的内涵。让古典智慧
        成为您的得⼒助⼿，指引您准确解读每个命局的⽞机。
      </div>
      <div
        style="
          margin: 0.32rem 2.496rem 0rem 1.312rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.48rem;
          color: #aeaeae;
          line-height: 0.736rem;
          text-align: left;
        "
      >
        For you to sort out "poor Tong Bao Jian", "drop heaven pulp", "three
        lives through the mee ting", "towel box secretThe classic discourse of
        classics such as "Terminology" intelligently matches every eight
        characters. No need to open the book, one touchGet deep ancient boo k
        wisdom to help you more fully understand the meaning of eight
        characters. Let classical wisdomTo be your right-hand man, guiding you
        to accurately interpret the mystery of each destiny.
      </div>
      <img :src="mobile_bg4" style="width: 100%; margin-top: 2.144rem" />
      <div
        style="
          height: 3.584rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img :src="text_4" alt="" style="width: 7.11008rem" />
      </div>
      <div
        style="
          margin: 0rem 1.344rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.736rem;
          line-height: 1.088rem;
          color: #000000;
        "
      >
        天⼄提供多种分类实战断法，配以详细真实案例，帮助您更深⼊
        理解命理学的精髓。平台的知识库涵盖多⽅⾯主题，从中获得实
        ⽤技巧和宝贵经验，提升您的专业⽔平。在每⼀次断法实战中不
        断学习，让您的命理解读更加独到准确。
      </div>
      <div
        style="
          margin: 0.32rem 2.496rem 0rem 1.312rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.48rem;
          color: #aeaeae;
          line-height: 0.736rem;
          text-align: left;
        "
      >
        Tianyi provides a variety of classification methods, with detailed real
        cases, to help you go deeperUnderstand the essence of numerology. The
        platform's knowledge base covers a wi de range of topics to gain insight
        Enhance your professional level with skills and valuable e xperience. In
        every case, noBreak learning, so that your numerology interpretation
        more uni que and accurate.
      </div>
      <img :src="mobile_bg5" style="width: 100%; margin-top: 2.144rem" />
      <div
        style="
          height: 3.584rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img :src="text_5" alt="" style="width: 7.11008rem" />
      </div>
      <div
        style="
          margin: 0rem 1.344rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.736rem;
          line-height: 1.088rem;
          color: #000000;
        "
      >
        六⽘排盘功能结合古代易学智慧和现代科技，为您提供准确的六
        ⽘解析服务。我们通过AI技术和⼤数据分析，为每⼀卦提供详尽
        的吉凶解析、变⽘说明及⽣活建议，帮助您洞悉卦象变化背后的 深层含义。
      </div>
      <div
        style="
          margin: 0.32rem 2.496rem 0rem 1.312rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.48rem;
          color: #aeaeae;
          line-height: 0.736rem;
          text-align: left;
        "
      >
        The six Lines array function combines ancient Yi learning wisdom and
        modern technology to provide you with accurate six lines analysis
        services. We use AI Technology and big da ta analysis provide detailed
        analysis of auspices and auspices for each hexagram, changes and lines,
        and life advice to help you understand the hexagram Like the deeper
        meaning b ehind the change.
      </div>
      <img :src="mobile_bg6" style="width: 100%; margin-top: 2.144rem" />
      <div
        style="
          height: 3.584rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img :src="text_6" alt="" style="width: 7.11008rem" />
      </div>
      <div
        style="
          margin: 0rem 1.344rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.736rem;
          line-height: 1.088rem;
          color: #000000;
        "
      >
        ⼋字合盘功能结合传统命理学和现代技术，为您提供精准的合盘
        解析服务。⽆论是个⼈婚姻匹配、事业合作还是⼈际关系分析，
        我们通过真太阳时排盘，⽀持公历、农历和甲⼦历，综合分析⼋
        字之间的相⽣相克关系，帮助您深⼊理解合盘中的吉凶宜忌。
      </div>
      <div
        style="
          margin: 0.32rem 2.496rem 0rem 1.312rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.48rem;
          color: #aeaeae;
          line-height: 0.736rem;
          text-align: left;
        "
      >
        The eight-figure disc function combines traditional numerology and
        modern technology to provide you with accurate disc analysis services.
        Marriage in private Marriage matching, ca reer cooperation or
        relationship analysis, we support the Gregorian calendar, lunar calendar
        and thyroid through the true Sun time chart Calendar, comprehensive
        analysis of the relatio nship between the eight characters, to help you
        deeply understand the good and bad in the plate.
      </div>
      <img :src="mobile_bg7" style="width: 100%; margin-top: 2.144rem" />
      <div
        style="
          height: 3.584rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img :src="text_7" alt="" style="width: 7.11008rem" />
      </div>
      <div
        style="
          margin: 0rem 1.344rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.736rem;
          line-height: 1.088rem;
          color: #000000;
        "
      >
        数据云存储，命盘随⼼留。我们提供云端存储功能，让您的命盘
        数据安全存储。⽆论您使⽤苹果、安卓、IPAD等多端设备，都能
        随时登录云端，实现数据同步。即便更换设备，也⽆需担⼼失去 重要数据。
      </div>
      <div
        style="
          margin: 0.32rem 2.496rem 0rem 1.312rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.48rem;
          color: #aeaeae;
          line-height: 0.736rem;
          text-align: left;
        "
      >
        The eight-figure disc function combines traditional numerology and
        modern technology to provide you with accurate disc analysis services.
        Marriage in private Marriage matching, ca reer cooperation or
        relationship analysis, we support the Gregorian calendar, lunar calendar
        and thyroid through the true Sun time chart Calendar, comprehensive
        analysis of the relatio nship between the eight characters, to help you
        deeply understand the good and bad in the plate.
      </div>
      <img :src="mobile_bg8" style="width: 100%; margin-top: 2.144rem" />
      <div style="position: relative">
        <bottom></bottom>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "@/mixins/comment";
import moment from "moment/moment";
import bottom from "@/components/bottom.vue";

export default {
  mixins: [mixin],
  name: "downloadMobile.vue",
  components: { bottom },
  data() {
    return {
      black: require("@/assets/div_1/tianyiback.png"),
      mobile_xiazai_button: require("@/assets/div_1/button.png"),
      endTime: "",
      mobile_bg1: require("@/assets/div_2/tianyi.png"),
      mobile_bg2: require("@/assets/div_1/background_3.png"),
      mobile_bg3: require("@/assets/div_1/background_4.png"),
      mobile_bg4: require("@/assets/div_1/background_5.png"),
      mobile_bg5: require("@/assets/div_1/background_6.png"),
      mobile_bg6: require("@/assets/div_1/background_7.png"),
      mobile_bg7: require("@/assets/div_1/background_8.png"),
      mobile_bg8: require("@/assets/div_1/background_9.png"),
      // mobile_bg9: require("@/assets/div_2/mobile_bg9.png"),
      text_1: require("@/assets/div_2/text_1.png"),
      text_2: require("@/assets/div_2/text_2.png"),
      text_3: require("@/assets/div_2/text_3.png"),
      text_4: require("@/assets/div_2/text_4.png"),
      text_5: require("@/assets/div_2/text_5.png"),
      text_6: require("@/assets/div_2/text_6.png"),
      text_7: require("@/assets/div_2/text_7.png"),
      // icon_2: require("@/assets/div_1/icon_2.png"),
      // back: require("@/assets/div_2/back.png"),
      // menu: require("@/assets/div_2/menu.png"),
    };
  },
  methods: {
    isHome() {
      return this.$route.name === "首页";
    },
  },
  created() {},
  mounted() {
    this.endTime = moment().format("YYYY");
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

img {
  display: block;
  height: auto;
}
</style>
