<template>
  <div style="width: 100%">
    <!--     第一栏-->
    <div class="div_a" style="">
      <div
        style="
          font-family: STKaiti, STKaiti;
          font-weight: normal;
          font-size: 28px;
          color: #443303;
        "
      >
        易喆云
      </div>
      <div style="width: 37%">
        <img
          :src="black"
          class="div_a_a"
          style="width: 100%"
          @load="loadImage"
        />
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: end;
          padding-bottom: 145px;
          margin-left: -35px;
        "
      >
        <img :src="mobile_xiazai_button" class="div_a_c" @click="goHome(11)" />
      </div>
    </div>
    <div v-show="show" class="content">
      <!--    第二栏-->
      <div
        class="div_b"
        style=""
        v-for="(item, index) in contentList"
        :key="index"
      >
        <!-- <img :src="imageUrl_2" class="div_b_a" style=""> -->
        <!--  -->
        <div class="div_b_b" style="justify-content: center;padding: 1rem 0;">
          <div class="div_b_b_a" style="">
            <img :src="item.title" width="100%" />
          </div>
          <div
            style="
              width: 25%;
              margin-left: 1.5rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
            <div
              style="
                font-family: DengXian-Bold, DengXian-Bold;
                font-weight: bold;
                font-size: 18px;
                color: #383534;
              "
            >
              {{ item.text }}
            </div>
            <div
              v-cloak
              style="
                color: #aeaeae;
                /* font-weight: 400; */
                font-family: PingFang-SC-Medium, PingFang-SC-Medium;
                font-weight: normal;
                /* font-size: 12rpx; */
                font-size: 0.192rem;
                line-height: 0.288rem;
                margin-top: 0.3rem;
              "
            >
              {{ item.text1 }}
            </div>
            <div></div>
          </div>
        </div>
        <div class="div_c">
          <img :src="item.imageUrl" width="75%" />
        </div>
      </div>
      
    </div>
    <!--    第13栏-->
    <div
        class="footer"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        "
      >
      <img :src="ba" style="width: 100%;height: 100%;position: absolute;z-index: 1; opacity: 0.3;" />
        <div style="width: 74%;z-index: 2;">
          <div style="width: 100%; float: left; padding-top: 41px">
            <div style="display: flex; margin-bottom: 31px">
              <img :src="icon_1" width="5%" />
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: bold;
                  font-size: 22px;
                  color: #000000;
                  margin-left: 5px;
                "
              >
                天乙盘
              </div>
            </div>
            <div style="width: 100%">
              <div
                style="
                  width: 65%;
                  margin-bottom: 0.12rem;
                  font-family: PingFang SC, PingFang SC;
                  color: #000000;
                  display: inline-block;
                  font-size: 0.192rem;
                "
              >
                © Copyright by 2024-{{ endTime }} itybz.com
              </div>
              <div
                style="
                  width: 35%;
                  margin-bottom: 0.12rem;
                  font-family: PingFang SC, PingFang SC;
                  color: #000000;
                  display: inline-block;
                  font-size: 0.192rem;
                "
              >
                邮箱：{{ name }}.com
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 0.32rem">
              <div
                style="
                  width: 65%;
                  margin-bottom: 0.22rem;
                  font-family: PingFang SC, PingFang SC;
                  color: #000000;
                  display: inline-block;
                  font-size: 0.192rem;
                "
              >
                版权所有 重庆易喆云网络科技有限公司
              </div>
              <div
                style="
                  width: 35%;
                  margin-bottom: 0.22rem;
                  font-family: PingFang SC, PingFang SC;
                  color: #000000;
                  display: inline-block;
                  font-size: 0.192rem;
                "
              >
                地址：重庆市丰都县三合街道平都大道西段274号7楼708室547号
              </div>
            </div>
            <div style="width: 100%">
              <div
                style="
                  display: inline-block;
                  text-decoration-line: underline;
                  color: #000000;
                  font-size: 0.192rem;
                  cursor: pointer;
                "
              >
                <span @click="goHome(4)">用户服务协议</span>
              </div>
              <div
                style="
                  display: inline-block;
                  text-decoration-line: underline;
                  color: #000000;
                  margin-left: 1rem;
                  font-size: 0.192rem;
                  cursor: pointer;
                "
              >
                <span @click="goHome(5)">隐私保护政策</span>
              </div>
            </div>
            <div style="text-align: center; margin: 0.864rem 0rem 0.32rem 0rem">
              <span
                style="font-size: 0.192rem; cursor: pointer"
                @click="goHome(9)"
                >{{ leftName }}</span
              >
              <img :src="icon_2" style="margin: 0rem 0.32rem; width: 0.24rem" />
              <span
                style="font-size: 0.192rem; cursor: pointer"
                @click="goHome(10, { recordcode: recordcode })"
                >{{ rightName }}</span
              >
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import mixin from "@/mixins/comment";
import moment from "moment";

export default {
  mixins: [mixin],
  name: "App",
  components: {},
  data() {
    return {
      contentList: [
        {
          title: require("@/assets/div_1/title_1.png"),
          text: "真太阳时精准排盘，多维度解读您的命理。我们的应用支持公历、农历和甲子历排盘，涵盖年月日时四柱、胎元、命宫、身宫、大运、流年、流日、流时等多个方面。不仅如此，还包括十二长生、 神煞、纳音、人元司令分野等运算，为您提供更为全⾯的命理信息。",
          text1:
            "True sun time precise arrangement,multi-dimensional interpretation of your numerology. Our application supports the Gregorian calendar, lunar calendar and Jiazi calendar platters, H anCover the four pillars, fetalelements, life palace, body palace, great luck, time, flow day, flow time and other aspects. Not only that,It also includes twelve Changsheng, Shen Sha, N a Yin, human Yuan commander, and other operations to provide you with more comprehe nsive numerologicalinformation.",
          imageUrl: require("@/assets/div_1/background_3.png"),
        },
        {
          title: require("@/assets/div_1/title_2.png"),
          text: "通过AI大数据运算，AI智能提示功能为您提供八字旺衰、八字格局、同党异党分析、五行能量占比等多方位信息。瞬间洞悉八字的精华，助您在批断命理时得心应手。让大数据的力量成为您的智囊，助您轻松断准八字！",
          text1:
            "Through AI big data operation, AI intelligent prompt function provides you with eight-figure prosperity and decline, eight-figure gridMulti-directional information such as bureau, partyanalysis, energy proportion of five elements, etc. Instant insight into the figure eightThe essence to help you in the determination of numerology. Make the power of big data yoursThink tank, help you easily break the eight-figure!",
          imageUrl: require("@/assets/div_1/background_4.png"),
        },
        {
          title: require("@/assets/div_1/title_3.png"),
          text: "为您梳理《穷通宝鉴》、《滴天髓》、《三命通会》、《巾箱秘术语》等名著的经典论述，智能匹配每个八字。无需翻书，一触即得深邃古籍智慧，助您更全面地理解八字的内涵。让古典智慧成为您的得力助手，指引您准确解读每个命局玄机。",
          text1:'For you to sort out "poor Tong Bao Jian", "drop heaven pulp", "three lives through the meeting", "towel box secretThe classic discourse of classics such as "Terminology" intelligentlymatches every eight characters. No need to open the book, one touchGet deep ancient book wisdom to help you more fully understand the meaning of eight characters. Let classical wisdomTo be your right-hand man, guiding you to accurately interpret the mystery of eachdestiny.',
          imageUrl: require("@/assets/div_1/background_5.png"),
        },
        {
          title: require("@/assets/div_1/title_4.png"),
          text: "天乙提供多种分类实战断法，配以详细真实案例，帮助您更深入理解命理学的精髓。平台的知识库涵盖多方面主题，从中获得实用技巧和宝贵经验，提升您的专业水平。在每一次断法实战中不断学习，让您的命理解读更加独到准确。",
          text1:
            "Tianyi provides a variety of classification methods, with detailed real cases, to help you go deeperUnderstand the essence of numerology. The platform's knowledge base covers a wide range of topics to gain insight Enhance your professional level with skills and valuable experience. In every case, noBreak learning, so that your numerology interpretation more unique and accurate.",
          imageUrl: require("@/assets/div_1/background_6.png"),
        },
        {
          title: require("@/assets/div_1/title_5.png"),
          text: "六爻排盘功能结合古代易学智慧和现代科技，为您提供准确的六爻解析服务。我们通过AI技术和大数据分析，为每一卦提供详尽的吉凶解析、变爻说明及生活建议，帮助您洞悉卦象变化背后的深层含义。",
          text1:
            "The six Lines array function combines ancient Yi learning wisdom and modern technology to provide you with accurate six lines analysis services. We use AI Technology and big data analysis provide detailed analysis of auspices and auspices for each hexagram, changes and lines, and life advice to help you understand the hexagram Like the deeper meaning behind the change.",
          imageUrl: require("@/assets/div_1/background_7.png"),
        },
        {
          title: require("@/assets/div_1/title_6.png"),
          text: "八字合盘功能结合传统命理学和现代技术，为您提供精准的合盘解析服务。无论是个人婚姻匹配、事业合作还是人际关系分析，我们通过真太阳时排盘，支持公历、农历和甲子历，综合分析八字之间的相生相克关系，帮助您深入理解合盘中的吉凶宜忌。",
          text1:
            "The eight-figure disc function combines traditional numerology and modern technology to provide you with accurate disc analysis services. Marriage in private Marriage matching, career cooperation or relationship analysis, we support the Gregorian calendar, lunar calendarand thyroid through the true Sun time chart Calendar, comprehensive analysis of the relationship between the eight characters, to help you deeply understand the good and bad in theplate.",
          imageUrl: require("@/assets/div_1/background_8.png"),
        },
        {
          title: require("@/assets/div_1/title_7.png"),
          text: "数据云存储，命盘随心留。我们提供云端存储功能，让您的命盘数据安全存储。无论您使用苹果、安卓、IPAD等多端设备，都能随时登录云端，实现数据同步。即便更换设备，也无需担心失去重要数据。",
          text1:
            "The eight-figure disc function combines traditional numerology and modern technology to provide you with accurate disc analysis services. Marriage in private Marriage matching, career cooperation or relationship analysis, we support the Gregorian calendar, lunar calendarand thyroid through the true Sun time chart Calendar, comprehensive analysis of the relationship between the eight characters, to help you deeply understand the good and bad in theplate.",
          imageUrl: require("@/assets/div_1/background_9.png"),
        },
      ],
      endTime: "",
      show: false,
      // imageUrl: require("@/assets/div_1/background.png"),
      imageUrl_2: require("@/assets/div_1/background_2.png"),
      imageUrl_3: require("@/assets/div_1/background_3.png"),
      imageUrl_4: require("@/assets/div_1/background_4.png"),
      imageUrl_5: require("@/assets/div_1/background_5.png"),
      imageUrl_6: require("@/assets/div_1/background_6.png"),
      imageUrl_7: require("@/assets/div_1/background_7.png"),
      imageUrl_8: require("@/assets/div_1/background_8.png"),
      imageUrl_9: require("@/assets/div_1/background_9.png"),
      // menuUrl_1: require("@/assets/div_1/menu_1.png"),
      // menuUrl_2: require("@/assets/div_1/menu_2.png"),
      // menuUrl_3: require("@/assets/div_1/menu_3.png"),
      title_1: require("@/assets/div_1/title_1.png"),
      title_2: require("@/assets/div_1/title_2.png"),
      title_3: require("@/assets/div_1/title_3.png"),
      title_4: require("@/assets/div_1/title_4.png"),
      title_5: require("@/assets/div_1/title_5.png"),
      title_6: require("@/assets/div_1/title_6.png"),
      title_7: require("@/assets/div_1/title_7.png"),
      icon_1: require("@/assets/div_1/天乙盘logoz.png"),
      icon_2: require("@/assets/div_1/icon_2.png"),
      black: require("@/assets/div_1/tianyiback.png"),
      // wz: require("@/assets/div_1/wz.png"),
      ba: require("@/assets/div_1/footer.png"),
      mobile_xiazai_button: require("@/assets/div_1/button.png"),
    };
  },
  mounted() {
    this.endTime = moment().format("YYYY");
  },
  methods: {
    loadImage() {
      this.show = true;
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0; /* 100/31 */
}

body {
  padding: 0;
  margin: 0; /* 100/31 */
}

html {
  padding: 0;
  margin: 0; /* 100/31 */
}

html {
  width: 100%;
}

.content {
  background-image: url("@/assets/div_1/content.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.div_a {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url("@/assets/div_1/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .div_a_a {
    /* display: inline-block */
  }

  .div_a_b {
    display: inline-block;
    position: absolute;
    top: 15%;
    left: 25%;
  }

  .div_a_c {
    /* position: absolute; */
    height: 250px;
    width: 130px;
    cursor: pointer;
    /* top: 74%;
    left: 50%;
    transform: translate(-50%, 0); */
  }
}

.div_b {
  position: relative;

  .div_b_a {
    display: inline-block;
    width: 100%;
  }

  .div_b_b {
    display: flex;

    .div_b_b_a {
      /* width: 20%; */
      display: flex;
      /* height: 2.5rem; */
      width: 0.7rem;
      text-align: center;
      padding-top: 50px;
      /* height: 40px; */
    }

    .div_b_b_b {
      width: 80%;
      display: flex;
      justify-content: center;

      .div_b_b_b_a {
        text-transform: none;
        height: 100%;
        box-sizing: border-box;

        .div_b_b_b_a_a {
          font-size: 0.288rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          line-height: 0.512rem;
        }

        .div_b_b_b_a_b {
          color: #aeaeae;
          font-weight: 400;
          font-size: 0.192rem;
          line-height: 0.288rem;
          margin-top: 0.3rem;
        }
      }
    }
  }
}

.div_c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  /* background-image: url("@/assets/div_1/footer.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  background-color: #f1f1f1;
}

</style>
