<template>
  <div style="width:100vw">
    <!--     第一栏-->
    <div style="position: relative">
      <div style="display: flex;justify-content: center;height: 1.6rem">
        <div style="width: 100%">
          <div
            style="display: inline-block;margin-top:0.48rem;margin-left:5.888rem;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.384rem;color: #0D0D0D">
            下载中心
          </div>
          <div
            style="display: inline-block;margin-top: 0.576rem;margin-left:2.88rem;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.288rem;color: #0D0D0D;">
            安卓版
          </div>
          <div
            style="display: inline-block;margin-top: 0.576rem;margin-left:1.088rem;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.288rem;color: #0D0D0D;">
            iPhone版
          </div>
          <div
            style="display: inline-block;margin-top: 0.576rem;margin-left:1.056rem;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.288rem;color: #0D0D0D;">
            小程序版
          </div>
          <div
            style="display: inline-block;margin-top: 0.576rem;margin-left:1.28rem;font-family: PingFang SC, PingFang SC;font-weight: 500;font-size: 0.288rem;color: #0D0D0D;">
            网页版
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-bottom: 24px">
        <div
          style="width: 55%;display: flex;justify-content: center;box-shadow: 0px 0px 13px 1px rgba(125,125,125,0.14);border-radius: 40px 40px 40px 40px;">
          <div
            style="box-sizing: border-box;width: 40%;display: flex;justify-content: center;align-items: center;padding-left: 22px">
            <div style="width: 25%"><img :src="icon_1" width="100%"></div>
            <div style="width: 60%;margin-left: 22px">
              <span
                style="font-weight: 400;font-size: 0.384rem;color: #0D0D0D;font-family: PingFang SC, PingFang SC;">玄易排盘</span>
              <br>
              <span style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.256rem;color: #0D0D0D;">黄历占卜万年历算命</span>
            </div>
          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem;position: relative"
          >
            <img v-if="!xuanyiAndroidShow" :src="Android" width="100%">
            <img v-if="xuanyiAndroidShow" :src="an" width="100%" @mouseleave="AndroidShowQR('xuanyi')">
            <img v-if="!xuanyiAndroidShow" :src="icon_4"
                 style="position: absolute;top:0.228rem;right: 0.228rem;cursor:pointer"
                 width="22%" @mouseover="AndroidShowQR('xuanyi')">

          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem;position: relative"
          >
            <img v-if="!xuanyiIosShow" :src="iPhone" width="100%">
            <img v-if="xuanyiIosShow" :src="ios" width="100%" @mouseleave="IosShowQR('xuanyi')">
            <img v-if="!xuanyiIosShow" :src="icon_4"
                 style="position: absolute;top:0.228rem;right: 0.228rem;cursor:pointer"
                 width="22%"
                 @mouseover="IosShowQR('xuanyi')"
            />
          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem">
            <img :src="dev" width="100%">
          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem">
            <img :src="dev" width="100%">
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center">
        <div
          style="width: 55%;display: flex;justify-content: center;box-shadow: 0px 0px 13px 1px rgba(125,125,125,0.14);border-radius: 40px 40px 40px 40px;">
          <div
            style="box-sizing: border-box;width: 40%;display: flex;justify-content: center;align-items: center;padding-left: 22px">
            <div style="width: 25%"><img :src="icon_3" width="100%"></div>
            <div style="width: 60%;margin-left: 22px">
              <span
                style="font-weight: 400;font-size: 0.384rem;color: #0D0D0D;font-family: PingFang SC, PingFang SC;">卜卜排盘</span>
              <br>
              <span style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.256rem;color: #0D0D0D;">八字排盘算命国学</span>
            </div>
          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem;position: relative"
          >
            <img v-if="!bubuAndroidShow" :src="Android" width="100%">
            <img v-if="bubuAndroidShow" :src="bubuAn" width="100%" @mouseleave="AndroidShowQR('bubu')">
            <img v-if="!bubuAndroidShow" :src="icon_4"
                 style="position: absolute;top:0.228rem;right: 0.228rem;cursor:pointer"
                 width="22%"
                 @mouseover="AndroidShowQR('bubu')"
            />
          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem;position: relative"
          >
            <img v-if="!bubuIosShow" :src="iPhone" width="100%">
            <img v-if="bubuIosShow" :src="bubuIos" width="100%" @mouseleave="IosShowQR('bubu')">
            <img v-if="!bubuIosShow" :src="icon_4"
                 style="position: absolute;top:0.228rem;right: 0.228rem;cursor:pointer"
                 width="22%"
                 @mouseover="IosShowQR('bubu')"
            />
          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem">
            <img :src="dev" width="100%">
          </div>
          <div style="box-sizing: border-box;width: 20%;padding: 0.16rem">
            <img :src="dev" width="100%">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {},
  data() {
    return {
      // icon_1: require('@/assets/div_1/icon_1.png'),
      // icon_3: require('@/assets/div_1/icon_3.png'),
      // dev: require('@/assets/div_1/dev.png'),
      // Android: require('@/assets/div_1/Android.png'),
      // iPhone: require('@/assets/div_1/iPhone.png'),
      // an: require('@/assets/div_1/an.png'),
      // bubuAn: require('@/assets/div_1/bubuAn.png'),
      // bubuIos: require('@/assets/div_1/bubuIos.png'),
      // ios: require('@/assets/div_1/ios.png'),
      // icon_4: require('@/assets/div_1/icon_4.png'),
      xuanyiAndroidShow: false,
      xuanyiIosShow: false,
      bubuAndroidShow: false,
      bubuIosShow: false,
    }
  },
  methods: {
    AndroidShowQR(v) {
      if (v === 'xuanyi') {
        this.xuanyiAndroidShow = !this.xuanyiAndroidShow;
      } else {
        this.bubuAndroidShow = !this.bubuAndroidShow;
      }

    },
    IosShowQR(v) {
      if (v === 'xuanyi') {
        this.xuanyiIosShow = !this.xuanyiIosShow;
      } else {
        this.bubuIosShow = !this.bubuIosShow;
      }

    }
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}


</style>
